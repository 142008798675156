.split-item-width {
    width: 100%;
}

.interactive-shopping {
    color: #a8d5ba;
    text-transform: uppercase;
    font-size: 22px !important;
    font-weight: 500;
}

.personalized-shopping {
    color: #2c3e50;
    font-size: 28px;
    font-weight: bold;
}


.ai-assistant {
    color: #2c3e50;
    font-size: 18px;
    font-weight: bold;
}

.reduces-cognitive {
    font-size: 16px;
}

.use-now {
    color: white;
    font-size: 16px;
    background-color: #34495e;
    padding: 10px 20px;
    border-radius: 5px;
    display: inline-block;
}

.arrow-icon::after {
    content: "→";
    margin-left: 5px;
}

.small-break {
    line-height: 0 !important;
}

.api-doc-button {
    display: inline-flex;
    align-items: center;
    background-color: #333;
    color: white;
    border-radius: 5px;
    text-decoration: none;
    margin-left: 40px;
    vertical-align: middle;
    height: 20px;
    font-size: 14px; /* Reduced from 20px */
    padding: 2px 10px;
}

.api-doc-button span {
    margin-right: 5px;
}

.api-doc-button svg {
    width: 12px;
    height: 12px;
}


.try-api-section {
    margin-top: 40px;
    text-align: center;
    width: 100%;
}

.try-api-section h2 {
    color: #43a047;
    margin-bottom: 20px;
    font-size: 28px;
}

.file-upload-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.file-upload-area {
    border: 2px dashed #43a047;
    border-radius: 10px;
    padding: 40px;
    background-color: #e8f5e9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    max-width: 500px;
}

.upload-icon {
    margin-bottom: 20px;
}

.upload-icon svg {
    stroke: #43a047;
}

.upload-text {
    font-size: 18px;
    color: #333;
    margin-bottom: 5px;
}

.file-formats {
    font-size: 14px;
    color: #666;
    margin-top: 5px;
    margin-bottom: 20px;
}

.select-file-btn {
    background-color: #43a047;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 16px;
    font-weight: bold;
}

.select-file-btn:hover {
    background-color: #2e7d32;
}

.split-item-width {
    width: 100%;
}

.interactive-shopping {
    color: #a8d5ba;
    text-transform: uppercase;
    font-size: 22px !important;
    font-weight: 500;
}

.personalized-shopping {
    color: #2c3e50;
    font-size: 28px;
    font-weight: bold;
}

.ai-assistant {
    color: #2c3e50;
    font-size: 18px;
    font-weight: bold;
}

.reduces-cognitive {
    font-size: 16px;
}

.use-now {
    color: white;
    font-size: 16px;
    background-color: #34495e;
    padding: 10px 20px;
    border-radius: 5px;
    display: inline-block;
}

.arrow-icon::after {
    content: "→";
    margin-left: 5px;
}

.small-break {
    line-height: 0 !important;
}

.api-doc-button {
    display: inline-flex;
    align-items: center;
    background-color: #333;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 16px;
    text-decoration: none;
    margin-left: 40px;
    vertical-align: middle;
    height: 30px;
}

.api-doc-button span {
    margin-right: 5px;
}

.api-doc-button svg {
    width: 14px;
    height: 14px;
}

.try-api-section {
    margin-top: 40px;
    text-align: center;
    width: 100%;
}

.try-api-section h2 {
    color: #43a047;
    margin-bottom: 20px;
    font-size: 28px;
}

.file-upload-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.file-upload-area {
    border: 2px dashed #43a047;
    border-radius: 10px;
    padding: 40px;
    background-color: #e8f5e9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    max-width: 500px;
}

.upload-icon {
    margin-bottom: 20px;
}

.upload-icon svg {
    stroke: #43a047;
}

.upload-text {
    font-size: 18px;
    color: #333;
    margin-bottom: 5px;
}

.file-formats {
    font-size: 14px;
    color: #666;
    margin-top: 5px;
    margin-bottom: 20px;
}

.select-file-btn, .confirm-btn, .reset-btn, .new-file-btn {
    background-color: #43a047;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 16px;
    font-weight: bold;
    margin: 10px;
}

.select-file-btn:hover, .confirm-btn:hover, .reset-btn:hover, .new-file-btn:hover {
    background-color: #2e7d32;
}

.loading {
    font-size: 18px;
    color: #43a047;
    margin-top: 20px;
}

.results {
    margin-top: 20px;
}

.results h3 {
    color: #43a047;
    margin-bottom: 10px;
}