@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Helvetica:wght@400&display=swap");

/* Global styles */
body {
    margin: 0;
    line-height: normal;
}

:root {
    /* fonts */
    --font-helvetica: Helvetica, sans-serif;
    --font-mulish: 'Mulish', sans-serif;

    /* Colors */
    --color-seagreen: #0e9453;

    /* Paddings */
    --padding-4xs-1: 8.1px;
}

.uploadFileBackground {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 6.5px;
    background-color: #e8f5ef;
    border: 1.6px dashed #47ca82;
    box-sizing: border-box;
    width: 754.44px;
    height: 426px;
}
.dragDrop {
    color: #0f0f0f;
}
.span {
    color: #333;
}
.browse {
    text-decoration: underline;
}
.dragDropContainer {
    position: relative;
    line-height: 39.02px;
}
.dragDropFiles {
    position: absolute;
    top: 180px;
    left: calc(50% - 180.22px);
    width: 366px;
    height: 56px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 8.129770278930664px;
    box-sizing: border-box;
}
.supportedFormatesExcel {
    position: relative;
    line-height: 29.27px;
}
.supportedFormats {
    position: absolute;
    top: 228px;
    left: calc(50% - 167.22px);
    width: 353px;
    height: 45px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 8.129770278930664px;
    box-sizing: border-box;
    font-size: 19.51px;
    color: #676767;
}
.uploadIcon {
    position: absolute;
    height: 22.74%;
    width: 16.59%;
    top: 11.83%;
    right: 41.95%;
    bottom: 65.42%;
    left: 41.46%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
}
.selectFile {
    position: relative;
    text-transform: uppercase;
}
.button {
    position: absolute;
    top: 305.68px;
    left: 289.42px;
    border-radius: 8.13px;
    background-color: #fbfdfe;
    border: 1.6px solid var(--color-seagreen);
    box-sizing: border-box;
    width: 157.72px;
    height: 60.16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 19.511449813842773px 26.01526641845703px;
    text-align: left;
    font-size: 16.26px;
    font-family: var(--font-helvetica);
}
.uploadFileBackgroundParent {
    position: absolute;
    top: 179px;
    left: 425px;
    width: 754.44px;
    height: 426px;
}
.a1Upload {
    width: 100%;
    position: relative;
    background-color: #fff;
    height: 982px;
    text-align: center;
    font-size: 26.02px;
    color: var(--color-seagreen);
    font-family: var(--font-mulish);
}

table.table thead tr th{
    border-bottom: 1px solid red;
}

.visibility {
    height: 10.18%;
    width: 5.29%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    cursor: pointer;
}



