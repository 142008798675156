.features-tiles {
    background-color: #7EB693;
    padding: 80px 0;
    width: 90%;
    margin: 0 auto;
}

.tiles-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -15px;
}

.tiles-item {
    flex: 0 0 calc(25% - 30px);
    max-width: calc(25% - 30px);
    margin: 15px;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.tiles-item-inner {
    padding: 24px;
}

.features-tiles-item-image {
    background-color: #E8F5E9;
    border-radius: 50%;
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 16px;
}

.button-primary {
    background-color: #2C3E50;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button-primary:hover {
    background-color: #34495E;
}

@media (max-width: 1024px) {
    .tiles-item {
        flex-basis: calc(50% - 30px);
    }
}

@media (max-width: 640px) {
    .tiles-item {
        flex-basis: 100%;
    }
}

.text-white h2 {
    color: #FFFFFF !important ;
}

.text-sub-title {
    color: #5a5a5a;
}

.text-description {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px !important;
    font-weight: 400 !important;
    color: #666666;
    line-height: 1.5;
    text-align: center;
    max-width: 280 px;
    margin: 10px auto;
}

.features-tiles-wrapper {
    width: 80%;
    margin: 0 auto;
}

@media (max-width: 768px) {
    .features-tiles {
        width: 100%;
    }
}

.features-tiles .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
}

