body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sidebar {
  width: 250px;
  height: 100vh;
  background-color: #66EE77;
  position: fixed;
  padding: 20px;
  box-sizing: border-box;
  text-align: left;
  justify-content: space-between;  /* add this line */
  border-right: 2px solid black;  /* This line adds the black border on the right */
}
.sidebar-title {
  padding-bottom: 12px;
}
.sidebar-icon {
  width: 20px; /* Adjust width as needed */
  height: 20px; /* Adjust height as needed */
  margin-right: 10px; /* Space between the icon and text */
  vertical-align: middle; /* Align with the text */
  margin-bottom: 5px;
}
.sidebar-bottom-logout {
  position: absolute; /* this will make it absolute related to sidebar */
  bottom: 18px; /* this will place it at the bottom of sidebar */
  left: 23px;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.logout-icon {
  width: 20px;  /* or whatever width you want */
  height: auto; /* maintain aspect ratio */
  margin-top: 2px;
  margin-right: 5px; /* space between the image and the text */
  vertical-align: middle; /* align it with the middle of the text */
}
.logout-text {
  margin-left: 10px;
}
ul.my-custom-list {
  list-style-type: none;
  text-align: left;
  list-style-type: none;
  padding-left: 0;
}

li.sidebar-element {
  background-color: white;
  border-radius: 10px;  /* Adjust as per your design needs */
  border: 2px solid black;  /* This line adds the border */
  padding: 10px; 
  margin-bottom: 20px;
}


li.sidebar-element a {
  text-decoration: none; 
  color: green;
}

.sidebar-element a:hover {
  color: darkgreen; /* A darker shade of green for hover */
  text-decoration: underline;
}

.main-content {
  height: 90vh;
  padding: 20px;
  margin-left: 230px; 
  display: flex; 
  flex-direction: column; 
}

.columns {
  display: flex; 
  flex: 1; 
}

.left-column {
  width: 900px;
  height: 650px;
  margin-left: 50px;
}

.right-column {
  flex-direction: column;
  height: 800px;
  margin-left: 35px;
}

.section {
  padding: 10px;
}

.graph-outline {
  border: 1px solid black;  
  height: 85%;
  width: 100%;    
}

.dash-section {
  width: 100%; 
  height: 100%; 
}

.stats-section1 {
  width: 300px;
  height: 190px;
  margin: 10px;
  margin-top: 0px;
}

.stats-section2 {
  width: 300px;
  height: 190px;
  margin: 10px;
}

.stats-section3 {
  width: 300px;
  height: 190px;
  margin: 10px;
}

.main-content h1 {
  margin: 0 0 20px 0; 
}

.error {
  color: red;
}

.info {
  color: green;
}

.loading {
  color: blue;
}


