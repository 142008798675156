.grid-container {
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  grid-template-rows: repeat(11, 1fr);
  gap: 10px;
}

.grid-item {
  border: 2px solid black;
  padding: 20px;
  font-size: 20px;
  text-align: center;
}

.grid-item-edit {
  border: 2px solid black;
  padding: 5px;
  font-size: 15px;
  text-align: center;
}

.grid-item-column-source {
  border: 2px solid black;
  padding: 20px;
  font-size: 14px;
  text-align: center;
}

.grid-item-column-destination {
  border: 2px solid black;
  padding: 20px;
  font-size: 20px;
  text-align: center;
  width:250px;
  font-size: 14px;
}

.drag-item {
  border: 2px solid black;
  padding: 10px;
  font-size: 15px;
  text-align: center;
}

.line {
  position: absolute;
  background-color: black;
  /* Set the initial position and size of the line */
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  z-index: 999; /* Ensure the line appears above other elements */
}

.page-item.active .page-link {
  background-color:  #64EA77 !important;
  border-color: #c3e6cb !important;
  color: #f1f7ff !important;
}

.pagination .page-link:not(.active) {
  color: #008000; /* Change the color to green for links that are not active */
}



