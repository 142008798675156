.tableContainer {
  border: 1px solid #ddd;
  border-radius: 1rem;
  overflow: hidden;
}
th {
  padding: 2%;
  border-right: 1px solid #ddd;
  background: #fafafa;
}
td {
  border-right: 1px solid #ddd;
  border-top: 1px solid #ddd;
  padding: 2%;
}

.rowStyle:hover {
  background-color: #fafafa;
  transition: 0.3s;
}
